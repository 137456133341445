<template>
  <div
    class="record_info"
    v-loading="loading"
    element-loading-text="准备中"
    element-loading-spinner="el-icon-loading"
  >
    <div class="card_list">
      <div class="card_item">
        <!-- <div class="card_top color_green" v-if="form.visitWay == '线上会议'">
          {{ form.visitWay }}
        </div>
        <div class="card_top color_blue" v-else-if="form.visitWay == '线下走访'">
          {{ form.visitWay }}
        </div>
        <div class="card_top color_purple" v-else-if="form.visitWay == '电话沟通'">
          {{ form.visitWay }}
        </div> -->
        <div class="card_left">
          <div class="img_box">
            <img v-if="form.companyLogo" :src="form.companyLogo" alt="" />
            <img v-else src="../../../../assets/image/enterprise-default-logo.png" alt="" />
          </div>
          <div class="info_box">
            <div class="row">
              <div class="enterprise_name">
                {{ form.companyFullName }}
              </div>
            </div>
            <div class="row">
              <div
                class="type_tag"
                v-for="(item, index) in newIndustryLabel(form.industryLabel).slice(0, 2)"
                :key="index"
              >
                {{ item }}
              </div>
              <el-popover placement="right" width="400" trigger="hover">
                <div class="row_tag">
                  <div
                    class="type_tag1"
                    v-for="(lo, lh) in newIndustryLabel(form.industryLabel).slice(2)"
                    :key="lh"
                  >
                    {{ lo }}
                  </div>
                </div>
                <div
                  class="type_tag"
                  slot="reference"
                  v-show="newIndustryLabel(form.industryLabel).length > 2"
                >
                  {{ newIndustryLabel(form.industryLabel).length - 2 }}+
                </div>
              </el-popover>
            </div>
            <div class="row">
              <div>
                <span>{{ form.district || '-' }}</span>
              </div>
              <span class="bottom_time">走访时间：{{ form.visitTime || '-' }}</span>
            </div>
          </div>
        </div>
        <div class="card_right">
          <div class="box_list">
            <div class="item">
              <div class="row">
                <span class="txt">接待人</span>
              </div>
              <div class="num" v-if="form.receiverInfo">有</div>
              <div class="num1" v-else>无</div>
            </div>
            <div class="item">
              <div class="row">
                <span class="txt">参与人</span>
              </div>
              <div class="num" v-if="form.participantInfo">有</div>
              <div class="num1" v-else>无</div>
            </div>
            <div class="item">
              <div class="row">
                <span class="txt">需求信息</span>
              </div>
              <div class="num" v-if="form.requirementDescribe">有</div>
              <div class="num1" v-else>无</div>
            </div>
            <div class="item">
              <div class="row">
                <span class="txt">解决方案</span>
              </div>
              <div class="num" v-if="form.solutionFile">有</div>
              <div class="num1" v-else>无</div>
            </div>
          </div>
          <div class="btn_box">
            <div class="set_manager">
              <span class="manager_name"> 客户经理： </span>
              <div class="middle_icon_box">
                <img v-if="form.customerHead" :src="form.customerHead" alt="" />
                <img v-else src="@/assets/image/wait-set-manager.png" alt="" />
              </div>
              <span class="manager_name">
                {{ form.customerName }}
              </span>
            </div>
            <div class="btn_list">
              <el-button type="primary" @click="toBack">返回一上级</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card_title">{{ simpleImageData.questionnaireName }}</div>
    <div class="card_content">
      <!-- 左边卡片 -->
      <el-card class="body_cards">
        <el-collapse v-model="activeName">
          <div v-for="(item, index) in dataList" :key="index" :id="`section${index}`">
            <el-collapse-item :name="index">
              <template slot="title">
                <div class="collapse_title">
                  <!-- 一级问题组名称 -->
                  <span class="group_name_one">{{ item.groupName }} </span>
                  <span v-if="hasScore" style="margin-left: 20px" class="corlor2"
                    >{{ item.atPresentScore }}分</span
                  >
                </div>
              </template>
              <div class="padding_j">
                <el-timeline>
                  <el-timeline-item
                    :timestamp="o.optionData"
                    v-for="(o, a) in item.voslist"
                    :key="a"
                  >
                    <div class="right_title_flex">
                      <div class="right_title">{{ o.caption }}</div>
                      <span style="margin-left: 20px">分数：{{ o.points || 0 }}</span>
                    </div>
                  </el-timeline-item>
                </el-timeline>
              </div>
            </el-collapse-item>
          </div>
        </el-collapse>
      </el-card>
      <!-- 右边卡片 -->
      <div class="body_card" v-if="diagnosisresult.evaluationType !== '3'">
        <div class="head_img">
          <div class="right_txt">
            <div style="margin-right: 30px">
              <span>等级</span>
              <span class="large_font">{{ getGrade }}</span>
              <span v-if="getGrade == 'L1'">(规划级)</span>
              <span v-else-if="getGrade == 'L2'">(规范级)</span>
              <span v-else-if="getGrade == 'L3'">(集成级)</span>
              <span v-else-if="getGrade == 'L4'">(优化级)</span>
              <span v-else-if="getGrade == 'L5'">(引领级)</span>
              <i class="el-icon-warning-outline"></i>
            </div>
            <div style="width: 0px; height: 24px; border: 1px solid #d4d4d4"></div>
            <div style="margin-left: 30px">
              <span>分数</span>
              <span class="large_font">{{ reportScore }}</span>
              <span>分</span>
              <i class="el-icon-warning-outline"></i>
            </div>
          </div>
        </div>

        <div class="right_content">
          <div>
            <!-- 分析图例 -->
            <div class="right_item">
              <div class="legend" style="padding: 20px" v-if="hasScore">
                <div class="item_tittle">
                  <span>分析图例</span>
                </div>
              </div>
              <div v-if="hasScore">
                <div class="legend_item" v-for="(item, index) in myChartList" :key="index">
                  <div class="item_name">
                    <div class="img_box">
                      <img
                        v-if="item.data.name == '数字化基础'"
                        src="../../../../assets/image/planning.png"
                        alt=""
                      />
                      <img
                        v-else-if="item.data.name == '数字化管理'"
                        src="../../../../assets/image/base.png"
                        alt=""
                      />
                      <img
                        v-else-if="item.data.name == '数字化成效'"
                        src="../../../../assets/image/apply.png"
                        alt=""
                      />
                      <img v-else src="../../../../assets/image/apply.png" alt="" />
                    </div>
                    <span>{{ item.data.name }}</span>
                  </div>
                  <!-- 雷达图 -->
                  <div
                    class="specificechart"
                    style="width: 650px; height: 350px; margin: auto"
                  ></div>
                </div>
              </div>
            </div>
            <!-- 底部建议 -->
            <div class="right_item">
              <div class="part">
                <div class="item_tittle">
                  <img src="../../../../assets/image/info1.png" alt="" />
                  <span class="title">综合评估建议</span>
                </div>
                <div class="row">
                  <div class="textarea">
                    <span>{{ simpleImageData.questionnaireEvaluationSuggestion }}</span>
                  </div>
                </div>
              </div>
              <div class="part">
                <div class="item_tittle">
                  <img src="../../../../assets/image/info2.png" alt="" />
                  <span class="title">专家建议</span>
                </div>
                <div v-for="(item, index) in simpleImageData.suggests" :key="index">
                  <div class="part_top">
                    <div class="i_box" @click="delsugest(index)" v-if="index > 0">
                      <i class="el-icon-delete"></i>
                    </div>
                  </div>
                  <div class="part_txt">
                    <div class="textarea">
                      <span>{{ item.suggest }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button plain @click="back">返回</el-button>
      <el-button type="primary" @click="downloadPdf" icon="el-icon-download"
        >下载分析报告</el-button
      >
    </div>
  </div>
</template>
  <script>
import {
  selectDiagnose,
  selectById,
  updateDiagnose,
  selectPolicyDeclaration,
  selectDiagnosticLogout,
  getDiagnosticTools,
  updateolicyState,
  downloadPdf,
  downloadPdfReport,
  updateRelease
} from '@/api/diagnosis'
import { getSelectById, getDiagnosticLogoutApi, getDiagnostic } from '@/api/visiting'
import { accAdd, base64ToFile } from '@/utils/function.js'
import { apiUpload, apiDownloadFile } from '@/api/common.js'

export default {
  name: 'Diagnosisrecord',

  data() {
    return {
      // 简易版图片url
      simpleImageData: {
        questionnaireName: '',
        score: 0, // 分数
        name: '', // 提交人
        createTime: '', // 提交时间
        companyName: '', // 企业名称
        diagnosticBasis: '', // 诊断依据
        evaluationSuggestion: '', // 综合评估建议
        isPolicy: [], // 可申报政策列表
        noPolicy: [], // 不可申报政策列表
        imgList: [] // 分析图例
      },
      activeNames: [],
      // 下载分析报告pdf的传参
      downloadParam: {
        id: this.$route.query.diagnoseId, // 诊断记录id
        urls: [] // 分析图片集合
      },
      files: [],
      hasScore: false, //是否有分数
      activeName: [],
      loading: false,
      recordlist: [], //问题组、问题、分数
      reportScore: 0, //分析图例 得分
      diagnoseId: this.$route.query.diagnoseId, //诊断书id
      //获取诊断详情数据下载pdf
      diagnosisresult: {},
      btns: {
        downloadPdfBtn: false // 禁用下载分析报告按钮
      }, // 控制按钮禁用
      form: {},
      dataList: [],
      myChartList: [] //雷达图数组
    }
  },
  computed: {
    // 根据分数计算等级
    getGrade() {
      if (this.reportScore <= 20) {
        return 'L1'
      } else if (this.reportScore > 20 && this.reportScore <= 40) {
        return 'L2'
      } else if (this.reportScore > 40 && this.reportScore <= 60) {
        return 'L3'
      } else if (this.reportScore > 60 && this.reportScore <= 80) {
        return 'L4'
      } else {
        return 'L5'
      }
    },
    id() {
      return this.$route.query.id
    }
  },
  mounted() {
    this.selectDiagnose()
  },
  created() {
    this.search()
    this.searchHeader()

    // this.getDiagnosticTools()
  },
  methods: {
    // 头部数据
    async searchHeader() {
      const res = await getSelectById({ id: this.id })
      if (res.resultCode === 200) {
        this.form = res.data
      } else {
        this.$message.warning('获取企业信息失败!')
      }
    },
    // 数据处理
    newIndustryLabel(val) {
      if (!val) {
        return []
      } else {
        return val.split(',')
      }
    },
    collChange() {
      this.activeName.push(this.activeNames)
      let sectionId = 'section' + this.activeNames
      this.scrollToSection(sectionId)
    },
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' }) // 平滑滚动到指定锚点
      }
    },
    // 返回上级
    toBack() {
      this.$router.go(-1)
    },

    // 折叠面板修改
    collapseChanged(val) {
      // console.log(val)
    },

    // 一级问题组为 企业基本信息 时
    getStrBol(str) {
      if (str === '企业基本信息') {
        return true
      } else {
        return false
      }
    },
    // 诊断记录数据
    async search() {
      const res = await getDiagnosticLogoutApi({ id: this.diagnoseId })
      if (res.resultCode == 200) {
        this.dataList = res.data
        this.activeName = []
        this.dataList.forEach((el, index) => {
          if (el.results) {
            let list = []
            el.results.forEach((o) => {
              if (o.results) {
                o.results.forEach((a) => {
                  list = list.concat(a.vos)
                })
              }
            })
            this.$set(el, 'voslist', list)
          }

          this.activeName.push(index)
        })

        this.createTime = res.data[0].results[0].vos[0].createTime
      } else {
        this.$message.warning('获取诊断信息失败!')
      }
    },

    //查询诊断详情  整体
    async selectDiagnose() {
      this.loading = true
      const res = await selectDiagnose({ id: this.diagnoseId })
      if (res.code === 200) {
        this.recordlist = res.data

        const item = this.recordlist[0]
        if (item.results && item.results.length > 0) {
          // 有二级问题组
          this.activeNames = [0, 100]
          const results = item.results[0].results
          if (results && results.length > 0) {
            // 有三级问题组
            this.activeNames = [0, 100, 1000]
          }
        }
        const bol = this.recordlist[0].atPresentScore != null
        const bol1 = this.recordlist.length > 0
        if (bol) {
          this.hasScore = true
        } else {
          this.hasScore = false
        }
        if (bol1 && bol) {
          //有一级问题组且有分数
          //第一个为大类的分析图例
          this.myChartList = [
            // {
            //   dataMax: [],
            //   data: {
            //     name: '整体分析图例',
            //     value: []
            //   }
            // }
          ]
          this.recordlist.map((item) => {
            //获取总得分
            this.reportScore = accAdd(Number(this.reportScore), Number(item.atPresentScore))
          })
          this.simpleImageData.score = this.reportScore // 分数 简易版图片

          this.getDiagnosticTools() // 获取总得分之后再获取建议数据
          this.recordlist.map((item, index) => {
            // set雷达图数据
            if (!item.displayScore) {
              item.displayScore = 0
            }
            if (!this.getStrBol(item.groupName)) {
              //   this.myChartList[0].dataMax.push({
              //     name: `${item.groupName}` + '(' + item.displayScore + '分' + ')',
              //     max: 100
              //   })
              //   this.myChartList[0].data.value.push(item.displayScore)
              if (item.results) {
                //是否有二级问题组
                if (item.results.length > 0) {
                  //是否有二级问题组  有二级问题组时添加myChartList的子元素
                  this.myChartList.push({
                    dataMax: [],
                    data: {
                      name: item.groupName,
                      value: []
                    }
                  })
                  item.results.map((i) => {
                    if (!i.displayScore) {
                      i.displayScore = 0
                    }
                    if (!i.results) {
                      //是否有三级问题组 最后一级为二级时雷达图数据为二级问题组名称
                      this.myChartList[index].dataMax.push({
                        name: `${i.groupName}` + '(' + i.displayScore + '分' + ')',
                        max: 100
                      })
                      this.myChartList[index].data.value.push(i.displayScore)
                    } else {
                      i.results.map((ii) => {
                        if (!ii.displayScore) {
                          ii.displayScore = 0
                        }
                        this.myChartList[index].dataMax.push({
                          name: `${ii.groupName}` + '(' + i.displayScore + '分' + ')',
                          max: 100
                        })
                        this.myChartList[index].data.value.push(ii.displayScore)
                      })
                    }
                  })
                }
              }
            }
          })
          console.log(this.myChartList)

          this.myChartList.forEach((el) => {
            const obj = {
              img: '',
              name: el.data.name
            }
            this.simpleImageData.imgList.push(obj)
          })
          this.$nextTick(() => {
            this.drawLine()
          })
        }
      } else {
        this.loading = false
        this.$message.warning('获取诊断详情信息失败!')
      }
    },

    //下载pdf诊断报告
    async downloadPdf() {
      this.btns.downloadPdfBtn = true
      this.loading = true
      // let nowdata = new Date()
      // let year = nowdata.getFullYear()
      // let month = nowdata.getMonth() + 1
      // let date = nowdata.getDate()
      // let nowtime = year + '-'
      // if (month < 10) {
      //   nowtime += '0'
      // }
      // nowtime += month + '-'
      // if (date < 10) {
      //   nowtime += '0'
      // }
      // nowtime += date
      // let download = `${this.diagnosisresult.companyName}-${this.diagnosisresult.questionnaireName}分析报告-${nowtime}.pdf`
      if (this.diagnosisresult.evaluationType === '2') {
        this.$message.warning('政策申报工具分析报告暂时无法下载，敬请期待~')
        this.btns.downloadPdfBtn = false
        this.loading = false
        // const res = await downloadPdf(this.diagnosisresult)
        // if (res.data) {
        //   this.btns.downloadPdfBtn = false
        //   this.loading = false
        //   let blob = new Blob([res.data])
        //   let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        //   const a = document.createElement('a') //创建a标签
        //   a.style.display = 'none'
        //   a.href = href // 指定下载'链接
        //   a.download = download //指定下载文件名
        //   // a.download = "test.png";
        //   a.click() //触发下载
        //   window.URL.revokeObjectURL(a.href) //释放URL对象
        //   if (document.body.contains(a)) {
        //     document.body.removeChild(a) //释放标签
        //   }
        // } else {
        //   this.btns.downloadPdfBtn = false
        //   this.loading = false
        //   this.$message.error('下载失败!')
        // }
      } else if (this.diagnosisresult.evaluationType === '3') {
        this.$message.warning('调查问卷工具分析报告暂时无法下载，敬请期待~')
        this.btns.downloadPdfBtn = false
        this.loading = false
      } else {
        const res = await downloadPdfReport(this.downloadParam)
        // 返回的是下载路径
        if (res.code === 200) {
          this.btns.downloadPdfBtn = false
          this.loading = false
          window.open(res.data)
          // let href = res.message //创建新的URL
          // const a = document.createElement('a') //创建a标签
          // a.style.display = 'none'
          // a.href = href // 指定下载'链接
          // a.download = download //指定下载文件名
          // a.click() //触发下载
          // window.URL.revokeObjectURL(a.href) //释放URL对象
          // if (document.body.contains(a)) {
          //   document.body.removeChild(a) //释放标签
          // }
        } else {
          this.btns.downloadPdfBtn = false
          this.loading = false
          this.$message.warning(res.msg)
        }
      }
    },
    //查询建议部分 右侧数据
    async getDiagnosticTools() {
      let obj = {
        reportScore: this.reportScore, // 总得分
        id: this.$route.query.diagnoseId
      }
      const res = await getDiagnostic(obj)
      if (res.resultCode == 200) {
        this.loading = false
        this.simpleImageData = res.data
      }
    },

    //返回上一级
    back() {
      this.$router.go(-1)
    },
    drawLine() {
      this.btns.downloadPdfBtn = true
      let specificEchart = document.getElementsByClassName('specificechart')
      this.myChartList.forEach((item, index) => {
        let myChart = this.$echarts.init(specificEchart[index])
        let option = {
          grid: {
            // 设置图表距离顶部,左侧，右侧和底部的高度
            top: '0px',
            left: '0px',
            right: '0px',
            bottom: '0px'
          },
          color: ['#68A2FF', '#FF9C00', '#0062FF'],
          //配置维度的最大值
          radar: {
            // name: {
            //     formatter: function (value, indicator) {
            //     // 使用回调函数来格式化指示器名称
            //     return [`{div1|${value}}`,`{div2|20分}`].join('\n'); // 显示名称和最大值
            // },
             
            //   rich: {
            //     div1: {
                 
            //       borderRadius: 5,
            //       padding: [5, 10],
            //       style: {
            //         fontSize: 16
            //         // 其他你想要的样式
            //       }
            //     },
            //     div2: {
            //       color: '#ff7a00',
                
            //       style: {
            //         fontSize: 18,
            //         padding: [5, 30],
            //         // 其他你想要的样式
            //       }
            //     }
            //   }
            // },

            axisName: {
              // color: '#0062FF',
              //backgroundColor: '#999',
              //borderRadius: 3,
              //padding: [3, 5]
              //指示器文字换行 start
              formatter: function (text) {
                var strlength = text.length
                if (strlength % 15 != 0) {
                  text = text.replace(/\S{15}/g, function (match) {
                    //console.log(match)
                    return match + '\n'
                  })
                } else {
                  text = text.replace(/\S{15}/g, function (match) {
                    //console.log(match)
                    return match + '\n'
                  })
                  strlength = text.length
                  text = text.substring(0, strlength - 1)
                }
                return text
              }
            },
            center: ['50%', '50%'], //调位置  第一个左右   第二个上下
            radius: 100, //调大小
            // 雷达图的指示器，用来指定雷达图中的多个变量（维度）
            indicator: item.dataMax,
            //对雷达图形设置成一个圆形,可选 circle:圆形,polygon:多角形(默认)
            shape: 'circle',
            splitArea: {
              itemStyle: {
                //color: '#FF7A00',
                //borderColor: '#FF7A00'
              },
              areaStyle: {
                // 图表背景网格区域的颜色
                //color: 'rgba(71,237,252,.3)'
                //color: '#FF7A00', //分割区域的背景色
              }
            },
            axisLine: {
              show: true, //雷达图中心发出的射线：坐标轴轴线相关设置
              lineStyle: {
                type: 'dashed',
                color: 'rgba(104,162,255,0.9)'
              }
            },
            splitLine: {
              //圆圈线条样式
              lineStyle: {
                color: 'rgba(104,162,255,.9)'
              }
            }
          },
          series: [
            {
              type: 'radar',
              label: {
                show: false, //控制是否显示数值
                fontSize: 12,
                color: '#FF7A00',
                margin: 20
              },
              // areaStyle: {}, //每个雷达图形成一个阴影的面积
              itemStyle: {
                //折线拐点标志的样式
                color: '#FF7A00',
                borderColor: '#FF7A00'
              },
              areaStyle: {
                // 折线围成区域的颜色
                color: '#FF7A00' //分割区域的背景色
              },
              lineStyle: {
                //线条样式
                color: '#FF7A00'
              },
              symbolSize: 4, //圆点大小
              symbol: 'circle', //圆点样式
              data: [item.data]
            }
          ]
        }
        myChart.setOption(option) // 绘制图表
        setTimeout(() => {
          this.loading = false
          var opts = {
            type: 'png', // 导出的格式，可选 png, jpeg
            pixelRatio: 1, // 导出的图片分辨率比例，默认为 1。
            backgroundColor: '#fff' // 导出的图片背景色，默认使用 option 里的 backgroundColor
          }
          //拿到base64 地址 传入files数组
          this.files.push(base64ToFile(myChart.getDataURL(opts), index))
          this.downloadParam.urls.push('')
          this.uploadFile(this.files[index], index)
        }, 1000)
        setTimeout(() => {
          this.btns.downloadPdfBtn = false
        }, 3000)
      })
    },
    // 上传图片
    async uploadFile(item, index) {
      const form = new FormData()
      form.append('file', item)
      const res = await apiUpload(form)
      if (res.resultCode === 200) {
        this.downloadParam.urls[index] = res.data
        this.simpleImageData.imgList[index].img = res.data
      }
    }
  }
}
</script>
  
  <style lang="less" scoped>
::v-deep .body_cards .el-icon-arrow-right:before {
  content: '展开'; /* 默认展开的文字 */
  font-size: 16px;
  color: #448aff;
}
::v-deep .body_cards .el-collapse-item__arrow.is-active:before {
  content: '收缩'; /* 展开后的文字 */
  font-size: 16px;
  color: #448aff;
}
::v-deep .el-collapse-item__arrow.is-active {
  transform: rotate(0deg);
}
::v-deep .card_left .el-collapse-item__wrap {
  will-change: height;
  background-color: #fff;
  display: none;
  overflow: hidden;
  box-sizing: border-box;
  border-bottom: 1px solid #ebeef5;
}
::v-deep .el-card {
  border-radius: 4px;
  border: 1px solid #fff;
  background-color: #fff;
  overflow: hidden;
  color: #303133;
  transition: 0.3s;
}
::v-deep .el-card.is-always-shadow {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.1);
}
::v-deep .card_left .el-collapse-item__arrow {
  margin: 0 8px 0 auto;
  transition: transform 0.3s;
  font-weight: 300;
  display: none;
}
::v-deep .el-collapse-item__content {
  padding-bottom: 0 !important;
}
::v-deep .el-collapse-item__header {
  display: flex;
  align-items: center;
  height: 52px;
  line-height: 48px;
  background-color: #fff;
  color: #303133;
  cursor: pointer;
  border-bottom: 1px solid #ebeef5;
  font-size: 18px;
  font-weight: 500;
  transition: border-bottom-color 0.3s;
  outline: 0;
}
::v-deep .el-collapse {
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ebeef5;
}
::v-deep .el-timeline {
  margin: 0;
  font-size: 14px;
  list-style: none;
  margin-top: 10px;
}
::v-deep .el-timeline-item__tail {
  position: absolute;
  left: 5px;
  height: 100%;
  border-left: 1px solid #448aff;
}
::v-deep .el-timeline-item__node--normal {
  left: -1px;
  width: 4px;
  height: 4px;
  background: #ffffff;
  /* 主色蓝 */
  border: 5px solid #448aff;
}

.color_green {
  background-color: #0ecbc6;
}
.color_blue {
  background-color: rgb(68, 138, 255);
}
.color_purple {
  background-color: #6a70ff;
}
.color_gray {
  background-color: #a1a1a1;
}
.record_info {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  .card_list {
    display: flex;
    flex-flow: column;
    width: 100%;
    .po_reportingStatus {
      position: absolute;
      color: #f53939;
      top: 50%;
      transform: translateY(-50%);
      left: 28%;
      z-index: 999 !important;
      img {
        width: 76px;
        height: 60px;
      }
    }
    .card_item {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: #fff;
      padding: 10px 20px;
      box-sizing: border-box;
      position: relative;
      .card_top {
        position: absolute;
        top: 10px;
        left: 6px;
        height: 30px;
        min-width: 84px;
        color: #ffffff;
        z-index: 99999;
        text-align: center;
        line-height: 30px;
        border-radius: 15px 20px 20px 0;
      }
      .card_left {
        display: flex;
        align-items: center;
        padding-top: 10px;
        box-sizing: border-box;
        width: 40%;
        margin-right: 20px;
        .item_checkbox {
          line-height: 36px;
          margin: 0px 10px;
        }
        .img_box {
          width: 100px;
          height: 100px;
          margin-right: 10px;
          border-radius: 8px;
          overflow: hidden;
          position: relative;
          img {
            width: 100px;
            height: 100px;
          }
          .tag_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 20px;
            color: #fff;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            position: absolute;
            top: 10px;
            left: 0;
            z-index: 999;
          }
        }
        .info_box {
          display: flex;
          justify-content: center;
          flex-flow: column;
          .bottom_time {
            margin-left: 20px;
          }
          .enterprise_name {
            cursor: pointer;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            color: #000;
            max-width: 340px; /* 固定宽度超出隐藏 */
            overflow: hidden; /*  超出隐藏 */
            text-overflow: ellipsis; /*  超出以 ...显示 */
            display: -webkit-box;
            -webkit-line-clamp: 1; /* // 显示1行 */
            -webkit-box-orient: vertical;
          }

          .tag_list {
            display: flex;
            .tag_item {
              font-size: 10px;
              color: #4e93fb;
              border: #559eff 1px solid;
              border-radius: 4px;
              padding: 5px;
              margin-right: 6px;
            }

            .show_more {
              position: relative;
              font-size: 14px;
              line-height: 26px;
              text-align: center;
              cursor: pointer;
              .show_tag_box {
                position: absolute;
                top: 100%;
                left: 100%;
                z-index: 99;
                width: 240px;
                padding: 6px;
                box-sizing: border-box;
                border-radius: 4px;
                background-color: #fff;
                border: #f8f8f8 1px solid;
                display: flex;
                flex-flow: row wrap;
                li {
                  padding: 3px 5px;
                  .tag {
                    width: fit-content;
                    font-size: 10px;
                    line-height: 20px;
                    color: #4e93fb;
                    border: #559eff 1px solid;
                    border-radius: 4px;
                    padding: 0 6px;
                    box-sizing: border-box;
                  }
                }
              }
            }
          }
        }
      }
      .card_right {
        flex: 1;
        display: flex;
        .box_list {
          display: flex;
          align-items: center;
          .item {
            display: flex;
            justify-content: center;
            text-align: center;
            margin-right: 30px;
            .num {
              cursor: pointer;
              font-weight: bold;
              font-size: 24px;
              color: #4e93fb;
              margin-bottom: 10px;
            }
            .num1 {
              font-weight: bold;
              font-size: 24px;
              color: #333333;
              margin-bottom: 10px;
            }
            .txt {
              font-size: 16px;
              margin-right: 15px;
              color: #151515;
            }
          }
          // .item:hover {
          //   .num {
          //     color: #448aff;
          //   }
          //   .icon_box {
          //     color: #448aff;
          //   }
          //   span {
          //     color: #448aff;
          //   }
          // }
        }
        .btn_box {
          display: flex;
          align-items: center;
          margin-left: auto;
          margin-right: 10px;
          .set_manager {
            display: flex;
            align-items: center;
            min-width: 120px;
            margin-right: 50px;
            .middle_icon_box {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 35px;
              height: 35px;
              border-radius: 50%;
              background-color: #f4f4f4;
              overflow: hidden;
              margin-right: 10px;
              img {
                width: 35px;
                height: 35px;
              }
              i {
                font-size: 16px;
                color: #c9c9c9;
              }
            }
            .manager_name,
            .wait_set {
              cursor: pointer;
              font-size: 16px;
            }
          }
          .divs {
            margin-right: 10px;
          }
          .set_manager {
            display: flex;
            align-items: center;
            min-width: 120px;
            margin-right: 50px;
            .middle_icon_box {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 35px;
              height: 35px;
              border-radius: 50%;
              background-color: #f4f4f4;
              overflow: hidden;
              margin-right: 10px;
              img {
                width: 35px;
                height: 35px;
              }
              i {
                font-size: 16px;
                color: #c9c9c9;
              }
            }
            .manager_name,
            .wait_set {
              cursor: pointer;
              font-size: 16px;
            }
          }
          .btn_list span {
            font-size: 14px;
          }
        }
      }
    }
  }
  .card_title {
    font-family: PingFang SC;
    font-size: 24px;
    font-weight: 550;
    color: #363e4d;
    height: 63px;
    line-height: 63px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    background-color: #deebff;
  }
  .card_content {
    display: flex;
    width: 100%;
    .card_left {
      min-width: 200px;
      align-items: center;
      border: 0.5px solid #fff;
      border-radius: 4px;
      height: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      background-color: #fff;
      overflow-y: auto;
      z-index: 9;
      /* 滚动条整体部分 */
      &::-webkit-scrollbar {
        width: 3px;
        height: 9px;
      }
      /* 滚动条里面的滑块 */
      &::-webkit-scrollbar-thumb {
        background-color: rgba(144, 147, 153, 0.3);
        border-radius: 20px;
        transition: background-color 0.3s;
      }
      .navigation_top {
        width: 100%;
        text-align: center;
        font-size: 16px;
        line-height: 22px;
        color: #1a79f6;
        border-bottom: 0.5px solid #e4e4e4;
        padding: 20px;
        box-sizing: border-box;
      }
      .navigation_content {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 20px 10px;
        box-sizing: border-box;
        .el-collapse {
          width: 100%;
          border: none;
          border-left: #d4d4d4 1px solid;
          padding: 10px;
          position: relative;
          .first_round,
          .second_round {
            position: absolute;
            left: -8px;
            width: 13px;
            height: 13px;
            background-color: #fff;
            border-radius: 50%;
            border: 1px solid #d4d4d4;
            z-index: 999;
          }
          .first_round {
            top: -10px;
          }
          .second_round {
            bottom: -10px;
          }
        }
        ::v-deep .el-collapse-item__header {
          border: none;
        }
        // ::v-deep .el-collapse-item__wrap {
        //   display: none;
        // }
        ::v-deep .is-active {
          color: #4e93fb;
        }
        .nav_level_one,
        .nav_level_two,
        .nav_level_three {
          position: relative;
          .li_round {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #d4d4d4;
            border: 1px solid #d4d4d4;
            position: absolute;
            top: 22px;
            left: -14px;
            z-index: 999;
          }
          .triangle_box {
            position: absolute;
            top: 18px;
            left: -14px;
            //在三角形底边设置一个边界颜色
            //其它3边设置相同颜色
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 10px solid #4e93fb;
            border-right: 10px solid transparent;
          }
        }

        .nav_level_one {
          font-size: 16px;
        }
        .nav_level_two {
          font-size: 14px;
        }
        .nav_level_three {
          font-size: 12px;
        }
        .not_icon ::v-deep i {
          display: none;
        }
        // .active_title::v-deep .is-active:first-child {
        //   color: #4e93fb;
        //   background-color: #fff !important;
        // }

        .left_border_box_list {
          position: relative;
          height: 100%;
          border-left: 1px solid #d4d4d4;
          box-sizing: border-box;
          // margin-right: 10px;
        }
      }
    }
    .body_card {
      flex: 1;
      margin-left: 16px;
      height: calc(100vh - 335px);
      overflow-y: auto;
      /* 滚动条整体部分 */
      &::-webkit-scrollbar {
        width: 3px;
        height: 9px;
      }
      /* 滚动条里面的滑块 */
      &::-webkit-scrollbar-thumb {
        background-color: rgba(144, 147, 153, 0.3);
        border-radius: 20px;
        transition: background-color 0.3s;
      }
      .right_title_flex {
        display: flex;
        justify-content: space-between;
      }
      .head_img {
        background: #fff;
        padding: 20px;
        display: flex;
        justify-content: right;
        .right_txt {
          display: flex;
          align-items: center;
          .large_font {
            font-size: 20px;
            margin: 0px 4px;
          }
          i {
            color: #ff7d18;
            margin-left: 5px;
          }
        }
      }
    }
    .body_cards {
      width: 45%;
      height: calc(100vh - 335px);
      overflow-y: auto;
      /* 滚动条整体部分 */
      &::-webkit-scrollbar {
        width: 3px;
        height: 9px;
      }
      /* 滚动条里面的滑块 */
      &::-webkit-scrollbar-thumb {
        background-color: rgba(144, 147, 153, 0.3);
        border-radius: 20px;
        transition: background-color 0.3s;
      }
      .right_title_flex {
        display: flex;
        justify-content: space-between;
      }
    }
    .collapse_title {
      display: flex;
      span {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
      }
      .atPresentScore {
        font-size: 24px;
        font-weight: 500;
        color: #4e93fb;
        margin-left: 20px;
      }
    }
    .top_start {
      font-size: 14px;
      color: #b6b6b6;
      background-color: #fafafa;
      overflow: auto;
      padding: 10px 20px;
      box-sizing: border-box;
      .txt {
        overflow-wrap: normal;
        font-size: 14px;
        color: #333;
      }
      .not_option_txt {
        overflow-wrap: normal;
        font-size: 14px;
        line-height: 22px;
        color: #8b8b8b;
        padding: 4px 0;
      }
      .not_select_options {
        font-size: 14px;
        line-height: 22px;
        color: #8b8b8b;
        margin-bottom: 10px;
      }
      .order_box {
        display: inline-block;
        height: 16px;
        width: 16px;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #fff;
        background-color: #314858;
        margin-right: 6px;
      }
    }
  }
}

.right_buttom {
  display: flex;
  .btn_item {
    // cursor: pointer;
    margin: 0 10px;
    position: relative;
    // .show_btns {
    //   width: 100%;
    //   text-align: center;
    //   background-color: #fff;
    //   margin-bottom: 10px;
    //   padding: 10px;
    //   box-sizing: border-box;
    //   position: absolute;
    //   top: 100%;
    //   left: 0;
    //   z-index: 999;
    //   box-shadow: 0px 4px 4px rgba(202, 202, 202, 0.25);
    //   .span_btn {
    //     cursor: pointer;
    //     margin: 10px;
    //   }
    // }
  }
}
.right_content {
  margin-top: 16px;
  .right_item {
    display: flex;
    flex-flow: column;
    border-bottom: #e8e8e8 1px dotted;
    .item_tittle {
      width: fit-content;
      color: #448aff;
      text-align: center;
      display: flex;
      align-items: center;
      img {
        width: 36px;
        height: 36px;
      }
      .title {
        font-weight: 550;
        margin-left: 10px;
      }
    }
    .textarea {
      min-height: 100px;
      padding: 10px 20px;
    }
    .btn {
      color: #448aff;
      cursor: pointer;
      padding-right: 20px;
      margin-left: auto;
      margin-right: 20px;
    }
  }
  .legend {
    display: flex;
    justify-content: space-between;
    background: #fff;

    .item_tittle {
      color: #fff;
      background: #5690f6;
      border-radius: 2px;
      padding: 5px;
      font-size: 14px;
    }
  }
  .declarable_policy {
    .policy_optioin {
      background: #fafafa;
      color: #448aff;
      font-size: 14px;
      margin: 16px;
      span {
        font-size: 14px;
        color: #333333;
      }
    }
    .policy_optioinNo {
      background: #fafafa;
      color: #999999;
      font-size: 14px;
      margin: 16px;
      span {
        font-size: 14px;
        color: #d4d4d4;
      }
    }
  }
}
.part {
  background: #fff;
  padding: 10px;
  margin-bottom: 16px;
}
.part_top {
  display: flex;
  justify-content: space-between;
  i {
    margin-right: 20px;
  }
}
.part_txt {
  padding: 20px;
  margin-bottom: 10px;
  background: #fff;
}
.dialog-footer {
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #eeeeee;
  background: #fff;
}
.caption {
  font-size: 16px;
  font-weight: 550;
  color: #333333;
}
.caption_chid {
  font-size: 14px;
  color: #afafaf;
}
::v-deep .part_txt .el-textarea__inner {
  display: block;
  height: 180px;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
  font-size: inherit;
  color: #606266;
  background-color: #fafafa;
  background-image: none;
  border: 1px solid #fafafa;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
::v-deep .part_txt .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #fafafa;
  border-bottom: 1px solid #dcdfe6;

  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
::v-deep .declarable_policy .el-collapse-item__header {
  display: flex;
  align-items: center;
  height: 52px;
  line-height: 48px;
  background-color: #fff;
  color: #303133;
  cursor: pointer;
  border-bottom: 1px solid #ebeef5;
  font-size: 18px;
  font-weight: 500;
  transition: border-bottom-color 0.3s;
  outline: 0;
  padding: 10px 0px;
}
.legend_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: #fff;
  margin-bottom: 16px;
  .item_name {
    min-width: 200px;
    height: 180px;
    border-right: #448aff 1px dotted;
    display: flex;
    align-items: center;
    justify-content: center;
    .img_box {
      width: 36px;
      height: 36px;
      margin-right: 5px;
      img {
        width: 36px;
        height: 36px;
      }
    }
    span {
      font-size: 18px;
      font-weight: 500;
      color: #0062ff;
    }
  }
}
.box_border {
  padding: 5px 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  border: #4e93fb 1px solid;
  span {
    font-size: 16px;
  }
}
.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .item1 {
    overflow: auto;
    width: 80%;
  }
  span {
    font-size: 16px;
  }
  .label {
    min-width: 120px;
    margin-right: 20px;
  }
}
.i_box {
  display: flex;
  align-items: center;
  i {
    cursor: pointer;
  }
}
.common_input {
  width: 60%;
}
.group_name_one {
  font-weight: 500 !important;
  font-size: 20px !important;
  color: #333 !important;
}
.group_name_two {
  font-weight: 500 !important;
  font-size: 18px !important;
  color: #4b4b4b !important;
}
.group_name_three {
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #4b4b4b !important;
}
.corlor1 {
  color: #999 !important;
}
.corlor2 {
  color: #4e93fb !important;
}
</style>
  