import request from '@/utils/request'

// 走访记录一览
export function listPage(data) {
  return request({
    url: '/visitRecord/selectVisitPage',
    method: 'post',
    data
  })
}
//走访记录详情
export function getSelectById(query) {
  return request({
    url: '/visitRecord/selectById',
    method: 'get',
    params: query
  })
}
//下载文件
export function downloadFile(query) {
  return request({
    url: '/public/downloadFile',
    method: 'get',
    responseType: 'blob',
    params: query
  })
}
// 导出走访记录
export function exportInterviewApi(query) {
  return request({
    url: '/visitRecord/exportInterviewLog',
    method: 'get',
    responseType: 'blob',
    params: query
  })
}
// 诊断记录
export function getDiagnosticLogoutApi(query) {
  return request({
    url: '/visitRecord/getDiagnosticLogout',
    method: 'get',
    params: query
  })
}
// 导出诊断记录
export function exportDiagnoseGroupApi(query) {
  return request({
    url: '/visitRecord/exportDiagnoseGroup',
    method: 'get',
    responseType: 'blob',
    params: query
  })
}
//所属行业
export function getIndustryVo(query) {
  return request({
    url: '/code/getIndustryVo',
    method: 'get',
    params: query
  })
}
//走访记录统计列表
export function selectVisitRecordStatistics(data) {
  return request({
    url: '/visitRecord/selectVisitRecordStatistics',
    method: 'post',
    data
  })
}
//走访记录统计头部数据
export function getVisitRecordHeadStatistics(query) {
  return request({
    url: '/visitRecord/getVisitRecordHeadStatistics',
    method: 'get',
    params: query
  })
}
//获取一级认领单位
export function getOneLevelUnit(query) {
  return request({
    url: '/visitRecord/getOneLevelUnit',
    method: 'get',
    params: query
  })
}
//走访记录统计列表-单个认领单位
export function selectVisitRecordDateStatistics(data) {
  return request({
    url: '/visitRecord/selectVisitRecordDateStatistics',
    method: 'post',
    data
  })
}
//走访记录详情
export function selectVisitRCustomer(data) {
  return request({
    url: '/visitRecord/selectVisitRecordCustomer',
    method: 'post',
    data
  })
}
//走访记录详情客户经理达标数量统计
export function getVisitRecordCustomerNum(data) {
  return request({
    url: '/visitRecord/getVisitRecordCustomerNum',
    method: 'post',
    data
  })
}
//走访工具的问题筛选参数
export function getVisitQuestionParam(query) {
  return request({
    url: '/visitRecord/getVisitQuestionParam',
    method: 'get',
    params: query
  })
}
//根据诊断记录id获取诊断建议
export function getDiagnostic(data) {
  return request({
    url: '/webQuestionnaire/getDiagnostic',
    method: 'get',
    params: data
  })
}
//获取所有的问卷信息
export function selectQuestionnaire(data) {
  return request({
    url: '/visitRecord/selectQuestionnaire',
    method: 'get',
    params: data
  })
}
//导批量出诊断记录
export function exportRecord(data) {
  return request({
    url: '/visitRecord/exportDiagnoseRecord',
    method: 'post',
    responseType: 'blob',
    data
  })
}
